import React, { useState } from 'react';

interface TalentDescriptionProps {
  description: string;
  truncated?: boolean;
}

const TalentDescription: React.FC<TalentDescriptionProps> = ({ description, truncated = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const maxLength = 450;
  const shouldTruncate = truncated && description.length > maxLength;
  const displayDescription = shouldTruncate && !isExpanded
    ? description.substring(0, maxLength) + '...'
    : description;

  return (
    <div className="talent-description">
      <div dangerouslySetInnerHTML={{ __html: displayDescription }} />
      {shouldTruncate && (
        <span className="readMore" onClick={toggleExpand}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </span>
      )}
    </div>
  );
};

export default TalentDescription;
